<script setup lang="ts">
import type { NuxtError } from "#app";

const props = defineProps({
  error: Object as () => NuxtError,
});

useHead({
  title: props.error.statusCode + " " + props.error.statusMessage + " | NextPark",
});

// const handleError = () => clearError({ redirect: "/" });
</script>

<template>
  <v-app>
    <NuxtLayout>
      <div class="container">
        <v-row class="mt-8 md:my-16 items-center text-center">
          <v-col cols="12" md="6">
            <h1 class="text-6xl md:text-8xl leading-tight tracking-tight text-black font-extrabold mb-8 md:mb-16">Oops!</h1>
            <h2 class="text-2xl md:text-5xl leading-tight tracking-tight text-gray font-extrabold mb-3">{{ error.statusMessage }}</h2>
            <h3 clas="mb-2 text-gray">{{ error.statusCode }}</h3>

            <v-btn color="primary" variant="tonal" size="x-large" class="mt-8" :to="localeRoute({ name: 'index' })"> Go back to home </v-btn>
          </v-col>
          <v-col cols="12" md="6">
            <img src="/images/others/404.svg" alt="404" class="w-full md:w-3/4 mx-auto" />
          </v-col>
        </v-row>
      </div>
    </NuxtLayout>
  </v-app>
</template>
