import { stringify } from "qs";

export default defineNuxtPlugin(async () => {
  const nuxt = useNuxtApp();
  const auth = useAuthStore();
  const conf = useRuntimeConfig();

  const baseFetch = $fetch.create({
    baseURL: conf.public.api,
    credentials: "include",

    onRequest: ({ options }) => {
      let csrf = useCookie("XSRF-TOKEN").value;

      // https://github.com/unjs/ofetch/issues/119
      if (options.params) {
        let convertedOptions = new URLSearchParams(stringify(options.params));
        options.params = Object.fromEntries(convertedOptions);
      }

      // https://nuxt.com/docs/guide/recipes/custom-usefetch
      const headers = (options.headers ||= {});
      if (Array.isArray(headers)) {
        if (csrf) {
          headers.push(["X-XSRF-TOKEN", csrf]);
        }
        if (auth.state.token) {
          headers.push(["Authorization", `Bearer ${auth.state.token}`]);
        }
        headers.push(["Accept-Language", nuxt.$i18n.locale.value]);
      } else if (headers instanceof Headers) {
        if (csrf) {
          headers.set("X-XSRF-TOKEN", csrf);
        }
        if (auth.state.token) {
          headers.set("Authorization", `Bearer ${auth.state.token}`);
        }
        headers.set("Accept-Language", nuxt.$i18n.locale.value);
      } else {
        if (csrf) {
          headers["X-XSRF-TOKEN"] = csrf;
        }
        if (auth.state.token) {
          headers["Authorization"] = `Bearer ${auth.state.token}`;
        }
        headers["Accept-Language"] = nuxt.$i18n.locale.value;
      }
    },

    onResponse: async (context) => {
      const { request, options, response } = context;

      // @ts-ignore
      const shouldSendCSRF = response.status === 419 && !options._retriedOn419;

      if (!shouldSendCSRF) return;

      // @ts-ignore
      context.options._retriedOn419 = true;

      await baseFetch("/v1/csrf");

      // @ts-expect-error TODO: for some reason it says options are incompatible
      context.response = await baseFetch(request, options);
    },

    onResponseError: ({ response }) => {
      if (response.status === 401) {
        auth.reset();
      }
    },
  });

  return {
    provide: {
      siteFetch: baseFetch,
    },
  };
});
