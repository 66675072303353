import { defineStore } from "pinia";
import type { AuthType, User } from "~/types/general";

interface State {
  user?: User;
  type?: AuthType;
  token?: string;
}

export const useAuthStore = defineStore(
  "auth",
  () => {
    const localeRoute = useLocaleRoute();

    const state = reactive<State>({
      user: undefined,
      type: undefined,
      token: undefined,
    });

    const authorize = (type: AuthType, token: string) => {
      state.type = type;
      state.token = token;
    };

    const setUser = (user: User) => {
      state.user = user;
    };

    const reset = async () => {
      state.user = undefined;
      state.type = undefined;
      state.token = undefined;
    };

    const logout = async () => {
      reset();
      return navigateTo(localeRoute({ name: "index" }));
    };

    const isCustomer = computed(() => state.type === "CUSTOMER");
    const isBooking = computed(() => state.type === "BOOKING");
    const isLoggedIn = computed(() => !!state.token && !!state.user);

    return {
      state,

      reset,
      logout,
      setUser,
      authorize,

      isCustomer,
      isBooking,
      isLoggedIn,
    };
  },
  {
    persist: true,
  }
);
