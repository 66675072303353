import { defineStore } from "pinia";

export const useReferralStore = defineStore(
  "referral",
  () => {
    interface State {
      referral?: string;
      affiliate?: string;
    }
    const state = reactive<State>({
      referral: undefined,
      affiliate: undefined,
    });

    const checkReferral = async () => {
      const route = useRoute();
      const referral = route.query.referral as string | undefined;
      const affiliate = route.query.affiliate as string | undefined;
      const clear = route.query.gclid as string | undefined;

      if (clear) {
        state.referral = undefined;
        state.affiliate = undefined;
        return;
      }

      if (referral) state.referral = referral;
      if (affiliate) state.affiliate = affiliate;
    };

    return {
      state,
      checkReferral,
    };
  },
  {
    persist: true,
  }
);
