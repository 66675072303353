import revive_payload_client_YTGlmQWWT5 from "/opt/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_i18n_1QOsfnRqbu from "/opt/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-i18n.js";
import vuetify_icons_tuyYNCzpQc from "/opt/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.js";
import unhead_neSs9z3UJp from "/opt/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/opt/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_fWYXZjzQhM from "/opt/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_VTarsGAzgb from "/opt/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_PKIXU5KmsI from "/opt/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_SeG0EjL5Ec from "/opt/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Ib6KnAhqrq from "/opt/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/apps/web/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/opt/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import vuetify_no_client_hints_hj4IM0CtsZ from "/opt/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.js";
import pwa_icons_Z9m1jdsncU from "/opt/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_V4UTG2qgGR from "/opt/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import switch_locale_path_ssr_lCxu0ZmxIx from "/opt/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_5C7QFp7tsq from "/opt/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_zl2pp1dYXk from "/opt/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import fetch_14DdzPiXUB from "/opt/apps/web/plugins/fetch.ts";
import gtm_client_OzmBuHLRIb from "/opt/apps/web/plugins/gtm.client.ts";
import startapp_muHmYqHPaF from "/opt/apps/web/plugins/startapp.ts";
import vuetify_nuxt_plugin_client_JOtFoHdxcp from "/opt/apps/web/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  revive_payload_client_YTGlmQWWT5,
  vuetify_i18n_1QOsfnRqbu,
  vuetify_icons_tuyYNCzpQc,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  _0_siteConfig_fWYXZjzQhM,
  payload_client_VTarsGAzgb,
  navigation_repaint_client_PKIXU5KmsI,
  chunk_reload_client_SeG0EjL5Ec,
  plugin_vue3_Ib6KnAhqrq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  vuetify_no_client_hints_hj4IM0CtsZ,
  pwa_icons_Z9m1jdsncU,
  pwa_client_V4UTG2qgGR,
  switch_locale_path_ssr_lCxu0ZmxIx,
  i18n_5C7QFp7tsq,
  plugin_zl2pp1dYXk,
  fetch_14DdzPiXUB,
  gtm_client_OzmBuHLRIb,
  startapp_muHmYqHPaF,
  vuetify_nuxt_plugin_client_JOtFoHdxcp
]