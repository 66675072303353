export const groupBy = <T extends Record<string, any>>(list: Array<T>, by: keyof T) => {
  return list.reduce((result: Record<string, T[]>, item) => {
    const group = item[by];
    if (!result[group]) {
      result[group] = [];
    }
    result[group].push(item);
    return result;
  }, {});
};
