
// @ts-nocheck


export const localeCodes =  [
  "pt"
]

export const localeLoaders = {
  "pt": [{ key: "../locales/pt.js", load: () => import("../locales/pt.js" /* webpackChunkName: "locale__opt_apps_web_locales_pt_js" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "__i18n_config_ts_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": true,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.ts",
  "locales": [
    {
      "code": "pt",
      "flag": "pt",
      "name": "Português",
      "language": "pt-PT",
      "files": [
        "/opt/apps/web/locales/pt.js"
      ]
    }
  ],
  "defaultLocale": "pt",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": true,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix",
  "lazy": true,
  "langDir": "./locales",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "https://nextpark.pt",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "parkings/airports": {
      "pt": "/estacionamento-aeroporto"
    },
    "parkings/stations": {
      "pt": "/estacionamento-estacao"
    },
    "contact": {
      "pt": "/contato"
    },
    "others/howtoselect": {
      "pt": "/como-escolher-estacionamento-aeroporto"
    },
    "others/whywe": {
      "pt": "/por-que-nextpark"
    },
    "others/extras-airclaim": {
      "pt": "/compensacao-da-companhia-aerea"
    },
    "others/extras-travelinsurance": {
      "pt": "/seguro-de-viagem"
    },
    "others/extras-carrentals": {
      "pt": "/aluguel-de-carros"
    },
    "others/rules-terms": {
      "pt": "/regras"
    },
    "others/rules-privacy": {
      "pt": "/politica-de-privacidade"
    },
    "others/howitworks": {
      "pt": "/como-funciona"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "pt",
    "flag": "pt",
    "name": "Português",
    "language": "pt-PT",
    "files": [
      {
        "path": "/opt/apps/web/locales/pt.js"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
