import validate from "/opt/node_modules/nuxt/dist/pages/runtime/validate.js";
import slash_45global from "/opt/apps/web/middleware/slash.global.ts";
export const globalMiddleware = [
  validate,
  slash_45global
]
export const namedMiddleware = {
  auth: () => import("/opt/apps/web/middleware/auth.ts"),
  "booking-auth": () => import("/opt/apps/web/middleware/booking-auth.ts"),
  "booking-unauth": () => import("/opt/apps/web/middleware/booking-unauth.ts"),
  "customer-auth": () => import("/opt/apps/web/middleware/customer-auth.ts"),
  "customer-unauth": () => import("/opt/apps/web/middleware/customer-unauth.ts"),
  "valid-date": () => import("/opt/apps/web/middleware/validDate.ts")
}