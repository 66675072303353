import { defineStore } from "pinia";
import type { PlacesResponse } from "~/types/api";
import type { Place } from "~/types/general";

interface State {
  places: Array<Place>;
  log: string;
}

export const useGlobalStore = defineStore("global", () => {
  const state: State = reactive({
    places: [],
    log: "",
  });

  const { $i18n, $siteFetch } = useNuxtApp();

  const init = async () => {
    const { places } = await $siteFetch<{ places: PlacesResponse }>("/api/init", {
      baseURL: "",
      params: { language: $i18n.locale.value },
    });

    state.places = places.data;
  };

  const getPlaces = (type: string) => {
    return groupBy(
      state.places.filter((x) => x.type === type),
      "country"
    );
  };

  return {
    state,
    getPlaces,

    init,
  };
});
