<script setup lang="ts">
import { useGlobalStore } from "./stores/global";
const globalStore = useGlobalStore();

const route = useRoute();
const { locale } = useI18n();
const i18nHead = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: "id",
  addSeoAttributes: true,
});

await useAsyncData(async () => {
  await globalStore.init();
  return {};
});

useHead(() => ({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - NextPark` : "NextPark";
  },
  htmlAttrs: {
    dir: i18nHead.value.htmlAttrs!.dir,
    lang: i18nHead.value.htmlAttrs!.lang,
  },
  link: [
    ...(route.meta.noI18n ? [] : i18nHead.value.link || []),
    {
      rel: "icon",
      href: "/favicon.ico",
      sizes: "any",
    },
    {
      rel: "apple-touch-icon",
      href: "images/logo/icons/apple-touch-icon-180x180.png",
    },
  ],
  meta: [
    ...(i18nHead.value.meta || []),
  ],
}));

// TODO: remove if locale change refreshes the page.
watch(locale, () => globalStore.init());
</script>

<template>
  <v-app>
    <NuxtPwaAssets />
    <NuxtLoadingIndicator />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </v-app>
</template>
